import { LANDING_PAGE_PATHS } from 'constants/landing-page';
import React from 'react';
import BaseLandingPage from 'style-book/landing-page/BaseLandingPage';
import SeoComponent from 'style-book/seo/SeoComponent';
import { lpServiceByPath } from 'utils/lp-utils';

import './index.css';

function PodcastersPage() {
  return <BaseLandingPage lpService={lpServiceByPath[LANDING_PAGE_PATHS.PODCASTERS]} />;
}

export default PodcastersPage;

export function Head() {
  const lpService = lpServiceByPath[LANDING_PAGE_PATHS.PODCASTERS];
  return (
    <SeoComponent
      title="Turn Your Data into Actionable Goals with goalskeeper.io"
      description={lpService.getSeoDescription()}
      canonicalUrl="goalskeeper.io/podcasters/"
    />
  );
}
